import About from "./about";
import { useState } from "react";
import slogan from "../assets/images/mwffBannerText.png";

const Home = (props) => {
  const [color, setColor] = useState(true);

  const changeColor = () => {
    //scroll points go up as the page is scrolled down
    if (window.scrollY <= 0) {
      setColor(true);
      // console.log("true");
      // console.log(window.scrollY);
    } else {
      setColor(false);
      // console.log("false");
      // console.log(window.scrollY);
    }
  };

  window.addEventListener("scroll", changeColor, true);

  return (
    <div className="homecontainer" id="homeidref">
      <div className="laxcontainer"></div>
      <div className="imgfilter"></div>
      <div className="slogancontainer">
        {/* <h1>
          MIDWEST <br />
          <span className="fear">FEAR FEST</span>
          <span className="fest">FEST</span>
        </h1> */}
        <img src={slogan} alt="" className="slogan" />
      </div>
      <div className="bottomgradient"></div>
      <a className={color ? "topbtn" : "topbtn activetopbtn"} href="#homeidref">
        TOP
      </a>
    </div>
  );
};

export default Home;
