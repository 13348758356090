import { RiGhostLine } from "react-icons/ri";
import { PiAlienBold } from "react-icons/pi";
import { GiCorn } from "react-icons/gi";
import { LiaEyeSolid } from "react-icons/lia";

const Categories = (props) => {
  return (
    <div className="categoriescontainer" id="categoriesid">
      <div className="categoriesref" id="categoriesidref"></div>
      <h1 className="cattitle">Categories</h1>
      <div className="categorycontainer">
        <div className="category horror">
          <div className="imgcontainer">
            <img
              src="https://images.unsplash.com/photo-1504701954957-2010ec3bcec1?q=80&w=3474&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
              className="catimg"
            />
          </div>
          <div className="catdets">
            <h1>
              <RiGhostLine />
              Horror
            </h1>
            <p>
              <span>SCARY</span>
              <span>SPOOKY</span>
              <span>SLASHY</span>
              <span>CHILLING</span>
              <span>SHOCKING</span>
            </p>
          </div>
        </div>
        <div className="category thriller">
          <div className="imgcontainer">
            <img
              src="https://images.unsplash.com/photo-1618590067824-5ba32ca76ce9?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8dGhyaWxsZXJ8ZW58MHx8MHx8fDA%3D"
              alt=""
              className="catimg"
            />
          </div>
          <div className="catdets">
            <h1>
              {" "}
              <LiaEyeSolid />
              Thriller
            </h1>
            <p>
              <span>Suspensful</span>
              <span>hair-raising</span>
              <span>frantic</span>
              <span>stirring</span>
            </p>
          </div>
        </div>
        <div className="category scifi">
          <div className="imgcontainer">
            <img
              src="https://images.unsplash.com/photo-1628026552437-59305f353694?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8c2NpJTIwZml8ZW58MHx8MHx8fDA%3D"
              alt=""
              className="catimg"
            />
          </div>
          <div className="catdets">
            <h1>
              <PiAlienBold />
              Sci-Fi
            </h1>
            <p>
              <span>Futuristic</span>
              <span>alien</span>
              <span>odd</span>
              <span>strange</span>
              <span>bizarre</span>
            </p>
          </div>
        </div>
        <div className="category local">
          <div className="imgcontainer">
            <img
              src="https://images.unsplash.com/photo-1602215541616-c8da9fb39461?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fGluZGlhbmF8ZW58MHx8MHx8fDA%3D"
              alt=""
              className="catimg"
            />
          </div>
          <div className="catdets">
            <h1>
              <GiCorn />
              Locally Grown
            </h1>
            <p>
              <span>Indiana</span>
              <span>support local</span>
              <span>Indy Filmmakers</span>
            </p>
          </div>
        </div>
      </div>
      <div className="hr"></div>
    </div>
  );
};

export default Categories;
