import { useState } from "react";
// import Link from "react-router-dom";
import logo from "../assets/images/mwffLogo3.png";

const Navbar = (props) => {
  const [scroll, setScroll] = useState(true);
  const [menubtn, setMenuBtn] = useState(false);

  const navScroll = () => {
    if (window.scrollY <= 0) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };
  window.addEventListener("scroll", navScroll, true);

  return (
    <div className={scroll ? "navbarcontainer" : "navbarcontainer navscrolled"}>
      <img src={logo} alt="" className="slogan" />
      <ul className={menubtn ? "activenavmenu" : ""}>
        <a href="#aboutidref" onClick={() => setMenuBtn(false)}>
          ABOUT
        </a>
        {/* <a className="activenav" href="#aboutidref">
          ABOUT
        </a> */}
        <a href="#howidref" onClick={() => setMenuBtn(false)}>
          FILMMAKERS
        </a>
        <a href="#schedidref" onClick={() => setMenuBtn(false)}>
          IMPORTANT DATES
        </a>
        <a href="#categoriesidref" onClick={() => setMenuBtn(false)}>
          CATEGORIES
        </a>
        <a href="#selectionsidref" onClick={() => setMenuBtn(false)}>
          2024 SELECTIONS
        </a>
        {/* <a href="#awardsidref" onClick={() => setMenuBtn(false)}>
          AWARDS
        </a> */}
        {/* <a href="#partnersidref">OUR PARTNERS</a> */}
        {/* <a href="#contactidref">DONATE</a> */}
        {/* <a href="#contactidref">CONTACT</a> */}
        {/* <a href="">Winners</a> */}
      </ul>
      <div
        onClick={() => {
          menubtn ? setMenuBtn(false) : setMenuBtn(true);
        }}
        className={menubtn ? "mobilemenubtn openmenubtn" : "mobilemenubtn"}
      >
        <div className="btntop"></div>
        <div className="btnbot"></div>
      </div>
    </div>
  );
};

export default Navbar;
