import logo from "./logo.svg";
import "./App.css";
import Home from "./components/home";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import "./assets/fonts/plasdrip.ttf";
import About from "./components/about";
import Schedule from "./components/schedule";
import Howitworks from "./components/howitworks";
import Awards from "./components/awards";
import Contact from "./components/contact";
import Selections from "./components/selections";

import { BrowserRouter } from "react-router-dom";
import Partners from "./components/partners";
import Categories from "./components/categories";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Navbar />
          <Home />
          <About />
          <Howitworks />
          <Schedule />
          <Categories />
          <Selections />
          {/* <Awards /> */}
          {/* <Partners /> */}
          {/* <Contact /> */}
          <Footer />
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
