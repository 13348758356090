const About = (props) => {
  return (
    <div className="aboutcontainer" id="aboutid">
      <div className="aboutref" id="aboutidref"></div>
      {/* <h1 className="aboutheader">About</h1> */}
      <div className="leftpic">
        <img
          src="https://images.unsplash.com/photo-1509479200622-4503f27f12ef?w=900&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTd8fHNjYXJ5JTIwbW92aWUlMjBzZXR8ZW58MHx8MHx8fDA%3D"
          alt=""
          className="leftimg"
        />
      </div>
      <div className="rightdetails">
        <p>
          Located in the heart of Indianapolis, Midwest Fear Fest will present a
          collection of short films spanning horror, sci-fi, and thriller genres
          at Kan-Kan Cinema on October 2, 2024.
          <br />
          <br />
          Our mission is to bring inventive cinema to Indianapolis while
          honoring filmmakers from all walks of life, both in our community and
          beyond. Whether you're a dedicated fan of horror and sci-fi or simply
          enjoy an evening of cinema, we hope you will join us for a thrilling
          experience that is sure to keep you on the edge of your seat.
        </p>
      </div>
      <div className="hr"></div>
    </div>
  );
};

export default About;
