import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { BiLogoVenmo } from "react-icons/bi";
import { Link } from "react-router-dom";
import logo from "../assets/images/mwffLogo3Text.png";

const Footer = (props) => {
  return (
    <div className="footercontainer" id="footerid">
      <div className="disclaimercontainer">
        <br />
        <p>&#169; MIDWEST FEAR FEST - ALL RIGHTS RESERVED</p>
        <p>midwestfearfest@gmail.com</p>
        <p className="cccplug">
          Website by{" "}
          <a href="https://www.circlecitycoders.com" target="_blank">
            Circle City Coders LLC
          </a>
        </p>
        {/* <input type="email" placeholder="Email..." /> */}
      </div>
      <div className="logocontainer">
        <img src={logo} alt="" className="logo" />
      </div>
      <div className="socialscontainer">
        <Link
          className="donatebtn"
          to="https://gofund.me/7b21663c"
          target="_blank"
        >
          DONATE
        </Link>
        <br />
        <Link to="https://www.facebook.com/midwestfearfest" target="_blank">
          <FaFacebook />
        </Link>
        <Link
          to="https://www.instagram.com/midwestfearfest?igsh=MWpwbTBnbjgwc2lkcw%3D%3D&utm_source=qr"
          target="_blank"
        >
          <FaInstagramSquare />
        </Link>
        {/* <Link to="https://venmo.com" target="_blank">
          <BiLogoVenmo />
        </Link> */}
      </div>
    </div>
  );
};

export default Footer;
