import { GiSpiderWeb } from "react-icons/gi";

const Schedule = (props) => {
  return (
    <div className="schedcontainer">
      {/* <GiSpiderWeb /> */}
      <div className="schedref" id="schedidref"></div>
      <h2>*Deadlines for filmmakers only</h2>
      <div className="datescontainer">
        <div className="date date1">
          <div className="circle">
            <img
              src="https://images.unsplash.com/photo-1550537687-c91072c4792d?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <h1>
              March 15 <br />{" "}
              <span className="smaller">Submissions Open to the Public</span>
            </h1>
          </div>
        </div>
        <div className="date date2">
          <div className="circle">
            <img
              src="https://images.unsplash.com/photo-1550537687-c91072c4792d?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <h1>
              March 18 <br /> <span>First Frights Deadline</span>
            </h1>
          </div>
        </div>
        <div className="date date3">
          <div className="circle">
            <img
              src="https://images.unsplash.com/photo-1550537687-c91072c4792d?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <h1>
              April 15 <br /> <span>Earlybird Deadline</span>
            </h1>
          </div>
        </div>
        <div className="date date2">
          <div className="circle">
            <img
              src="https://images.unsplash.com/photo-1550537687-c91072c4792d?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <h1>
              July 8 <br />{" "}
              <span className="smaller">Regular Submission Deadline</span>
            </h1>
          </div>
        </div>
        <div className="date date3">
          <div className="circle">
            <img
              src="https://images.unsplash.com/photo-1550537687-c91072c4792d?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <h1>
              August 2 <br /> <span>Late Entry Deadline</span>
            </h1>
          </div>
        </div>
        <div className="date date2">
          <div className="circle">
            <img
              src="https://images.unsplash.com/photo-1550537687-c91072c4792d?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <h1>
              August 19 <br /> <span>Extended Deadline</span>
            </h1>
          </div>
        </div>
        <div className="date date3">
          <div className="circle">
            <img
              src="https://images.unsplash.com/photo-1550537687-c91072c4792d?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <h1>
              September 2 <br /> <span>Notification Date</span>
            </h1>
          </div>
        </div>
        <div className="date date2">
          <div className="circle">
            <img
              src="https://images.unsplash.com/photo-1550537687-c91072c4792d?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <h1>
              October 2 <br />{" "}
              <span className="smaller">Event Date - Come Join Us!</span>
            </h1>
          </div>
        </div>
      </div>
      <div className="hr"></div>
    </div>
  );
};

export default Schedule;
