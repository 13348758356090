import { RiGhostLine } from "react-icons/ri";
import { PiAlienBold } from "react-icons/pi";
import { GiCorn } from "react-icons/gi";
import { LiaEyeSolid } from "react-icons/lia";

const Selections = (props) => {
  let selections = require("../assets/selections.json");

  return (
    <div className="selectionscontainer" id="selectionsid">
      <div className="selectionsref" id="selectionsidref"></div>
      <h1 className="selectionstitle">2024 Official Selections</h1>
      <div className="selectioncontainer">
        {selections.map((selection, index) => (
          <div className="selection">
            {/* <div className="selectioncount">
              <span>
                {index + 1} / {selections.length}
              </span>
            </div> */}
            <div className="imgcontainer">
              <img
                src={require("../assets/images/" + selection.poster)}
                alt=""
                className="selectionimg"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="hr"></div>
    </div>
  );
};

export default Selections;
