import { PiFilmSlateBold } from "react-icons/pi";
import { VscSend } from "react-icons/vsc";
import { HiOutlineMailOpen } from "react-icons/hi";
import { LuPartyPopper } from "react-icons/lu";

import { Link } from "react-router-dom";

const Howitworks = (props) => {
  // let navigate = useNavigate();
  // const routeChange = () => {
  //   let path = `https://www.filmfreeway.com/`;
  //   navigate(path);
  // };

  return (
    <div className="howcontainer" id="howid">
      <div className="howref" id="howidref"></div>
      <div className="how how1">
        <PiFilmSlateBold />
        <h1>Make Film</h1>
        <p>
          This is where the magic happens! Slash, stab and float your way to a
          flick to be proud of.
        </p>
      </div>
      <div className="how how2">
        <VscSend />
        <h1>Enter Film</h1>
        <p>
          Click on the Film Freeway link below and submit your film to be
          reviewed by our panel of festival judges.
        </p>
        <Link
          className="submitbtn"
          to="https://filmfreeway.com/MidwestFearFest"
          target="_blank"
        >
          Submit
        </Link>
      </div>
      <div className="how how3">
        <HiOutlineMailOpen />
        <h1>Wait for Response</h1>
        <p>
          Sit tight and watch for an email from our team. Whether your film was
          selected or not, you will receive a response email.
        </p>
      </div>
      <div className="how how4">
        <LuPartyPopper />
        <h1>Enjoy MWFF!</h1>
        <p>
          Come out to the Kan-Kan on October 2nd, 2024 for food, drinks and fun!
        </p>
        <Link
          className="submitbtn ticketbtn"
          to="https://www.goelevent.com/Kan-KanCinemaandBrasserie/e/MIDWESTFEARFEST"
          target="_blank"
        >
          Get Tickets
        </Link>
        <Link
          className="submitbtn directions"
          to="https://www.google.com/maps/place/Kan-Kan+Cinema+and+Restaurant/@39.7848327,-86.1321366,17z/data=!3m1!4b1!4m6!3m5!1s0x886b51874cd13e55:0x11caa0c1bc8788fe!8m2!3d39.7848286!4d-86.1295617!16s%2Fg%2F11j4pr0zt_?entry=ttu"
          target="_blank"
        >
          Directions
        </Link>
      </div>
      <div className="hr"></div>
    </div>
  );
};

export default Howitworks;
